import axios from "axios";
import download from "downloadjs";
import { useEffect, useState, useRef } from "react";
import { Link, Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import * as pdfjs from "pdfjs-dist/build/pdf";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import { useAuth } from "../AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive, faBan, faBell, faCheckCircle, faDownload, faEdit, faInbox, faPaperPlane,
  faSignature, faTrash
} from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../utils";
import Page from "./Page";
import FillInput from "./FillInput";
import useWindowDimensions from "../utils";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "../SnackbarContext";

export default function View() {
  const navigate = useNavigate();
  const { docid } = useParams();
  const { setDocs } = useOutletContext();
  const [user] = useAuth();
  const [doc, setDoc] = useState();
  const [pdf, setPdf] = useState();
  const [currentSigner, setCurrentSigner] = useState();
  const [activeInput, setActiveInput] = useState();
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState();
  const [scale, setScale] = useState(1);
  const docRef = useRef();
  const boxRef = useRef();
  const { width } = useWindowDimensions();
  const [navbarWidth, setNavbarWidth] = useState(336);
  
  const location = useLocation();
  const { pathname } = location;
  
  const [isMobile, setIsMobile] = useState(width < 1280);

  const showSnackbar = useSnackbar();

  useEffect(() => {
    setIsMobile(width < 1280);
  
    if (pathname.toLowerCase().includes('signer-home')) {
      if (width < 992) {
        setNavbarWidth(40);
      }else if (width < 1280) {
        setNavbarWidth(320);
      }  
    } else {
      if (width > 991) {
        setNavbarWidth(336);
      }else if (width < 1280) {
        setNavbarWidth(65);
      }
    }
  }, [width, pathname]);

  const remainingInputs = (doc, { optional = false } = {}) => {
    let pts = doc?.inputs?.filter(input =>
      input.signerid === currentSigner?.signerid
      && (input.required) && !input.textdata?.length && !input.filled
    );
    return pts;
  }

  useEffect(() => {
    setDoc(null);
    (async () => {
      try {
        const doc = (await axios.get(`${process.env.REACT_APP_API_URL}/docs/${docid}/data`)).data;
        setDoc(doc);
        console.log(doc);
        
        const currentSigner = doc.signers.find(signer => signer.userid === user.userid);
        const href = window.location.href;
        const path = window.location.pathname;
        if (href.includes("awaiting-signiture") || href.includes("complete")) {
          setInputs([]);
        } else {
          setInputs(
          ["drafts", "templates"].some((v) => path.includes(v))
            ? doc.inputs
            : doc.inputs.filter(
              (input) => input.signerid === currentSigner?.signerid
            )
          );
        }

        setCurrentSigner(currentSigner);
        await refreshPdf(doc);
      } catch (err) {
        if (err.response) {
          console.error(err.response);
          setErr(err.response?.data);
        } else {
          console.error(err);
          setErr(err);
        }
      }
    })();
  }, [docid]);

  async function refreshPdf(doc) {
    try {
      setPdf(null);
      const pdf = await pdfjs.getDocument(new Uint8Array(doc.data.data)).promise;
      const page = await pdf.getPage(1);
      let scale = document.getElementById('doc').getBoundingClientRect().width / page?.view[2];
      if (scale > 2.2) scale = 2.2;
      if (scale < 0.8) scale = 0.8;
      setScale(scale);
      setPdf(pdf);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  }

  async function refreshDocs() {
    setDocs([]);
    let docs = (await axios.get(`${process.env.REACT_APP_API_URL}/docs`)).data;
    setDocs(docs);
  }

  async function sign() {
    const inputs = remainingInputs(doc, { optional: true });
    if (inputs.length != 0) {
      if (user.state == 0) {
        navigate(`/signer-home/inbox/${doc.docid}/fields`);
      }
      else {
        navigate(`/home/inbox/${doc.docid}/fields`);
      }
      focusNext();
    }
    else if (window.confirm(`Are you sure you want to sign document ${doc.name}?`)) {
      try {
        setLoading(true);
        const res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/sign`)).data;
        setDoc(res);
        await refreshDocs();
        navigate(`/home`);
      } catch (error) {
        console.error(error);
        setErr(error.response?.data);
        if (error.response.status === 500) {
          showSnackbar(error.message, "error");
          setTimeout(() => {
            showSnackbar("The file might be corrupted. Please try a different file.", "error");
          }, 3000);
        }
        showSnackbar(error.message, "error");
      } finally {
        setLoading(false);
      }
    }
  }

  async function moveDoc(dest) {
    try {
      let res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}/${dest}`)).data;
      await refreshDocs();
      navigate("..");
    } catch (err) {
      console.error(err);
      setErr(err.response?.data);
    }
  }

  async function deleteDoc() {
    if (window.confirm(`Are you sure you want to delete document ${doc.name}?`)) {
      try {
        let res = (await axios.delete(`${process.env.REACT_APP_API_URL}/docs/${doc.docid}`)).data;
        await refreshDocs();
        navigate("..");
      } catch (err) {
        console.error(err);
        setErr(err.response?.data);
      }
    }
  }

  function focusNext() {
    const inputs = remainingInputs(doc, { optional: true });
    let newIndex = inputs.indexOf(activeInput) + 1;
    if (newIndex >= inputs.length || newIndex < 0) newIndex = 0;
    const newInput = inputs[newIndex];
    setActiveInput(newInput);
    document.getElementById(`i${newInput?.inputid}`)?.focus();
  }

  function editDoc() {
    if(doc.state == 1)
      return `/package/${docid}?template=true&state=1`
    else
      return `/package/${docid}?template=true&state=0`
  }

  function scaling(event) {
    if(event.target.value != "") {
      setScale(event.target.value / 100);
    }
    else {
      setScale(null);
    }
  }

  function setScaleValue() {
    if(scale == null){
      return scale;
    } else {
      return Math.round(scale * 100);
    }
  }

  function mobileScale(type) {
    let newScale = Math.round(scale * 100);
    if(type) {
      setScale((newScale += 10) / 100)
    } else {
      setScale((newScale -= 10) / 100)
    }
  }

  function renderButtons() {
    switch (doc?.state) {
      case 0:
      case 1:
        return <>
          <Stack className={isMobile ? "w-100 pb-3" : ""}>
            <h5 className="mb-1 text-break">{doc?.name}</h5>
            <small className="text-muted">Created: {formatDate(doc?.timecreated)}</small>
          </Stack>
          {!!doc?.signers.length && <Button as={Link} to={`send/${doc?.state ? "template" : "once"}`} variant="dark" className="ms-auto orange">
            <FontAwesomeIcon icon={faPaperPlane} /> {isMobile ? '' : 'Send'}
          </Button>}
          <Button as={Link} to={editDoc()} variant="light" className="ml-1">
            <FontAwesomeIcon icon={faEdit} /> {isMobile ? '' : 'Edit'}
          </Button>
          <Button variant="light" onClick={deleteDoc} className="ml-1">
            <FontAwesomeIcon icon={faTrash} /> {isMobile ? '' : 'Delete'}
          </Button>
        </>
      case 2: {
        return <>
          <Stack className={isMobile ? "w-100 pb-3" : ""}>
            <h5 className="mb-1">{doc?.name}</h5>
            <span>
              <span className="text-muted">to: </span>{doc.signers.map(signer => signer.name).join(", ")}
              <span className="text-muted ms-2">from: </span>{doc.owner?.firstname} {doc.owner?.lastname}
              <span className="text-muted ms-2">sent: </span>{formatDate(doc?.timesent)}
            </span>
          </Stack>
          {currentSigner?.state === 1
            ? <>
              <Button variant="dark" className="orange" onClick={sign}>
                {loading
                  ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Signing</>
                  : <><FontAwesomeIcon icon={faSignature} /> {isMobile ? '' : 'Sign'}</>}
              </Button>
              <Button as={Link} to="reject" variant="light" className={isMobile ? "ml-1" : ""}>
                <FontAwesomeIcon icon={faBan} /> {isMobile ? '' : 'Reject'}
              </Button>
            </>
            : <>
              <span className={isMobile ? "text-muted me-2 w-100 pb-3" : "text-muted me-2"}>Waiting for other signers</span>
              {doc?.ownerid === user.userid && <Button as={Link} to="remind" variant="light">
                <FontAwesomeIcon icon={faBell} /> {isMobile ? '' : 'Remind'}
              </Button>}
              {doc.ownerid === user.userid && <Button variant="light" onClick={deleteDoc} className={isMobile ? "ml-1" : ""}>
                <FontAwesomeIcon icon={faTrash} /> {isMobile ? '' : 'Delete'}
              </Button>}
            </>
          }
        </>
      }
      case 3:
      case 4:
        return <>
          <Stack className={isMobile ? "w-100" : ""}>
            <span>
              <span className="h5 mb-1 display-inline me-3 text-break">{doc?.name}</span>
              {doc?.validation?.message && <CertificatePopup cert={doc?.validation?.message?.cert} />}
            </span>
            <span>
              <span className="text-muted">to: </span>{doc.signers.map(signer => signer.name).join(", ")}
              <span className="text-muted ms-2">from: </span>{doc.owner?.firstname} {doc.owner?.lastname}
              <span className="text-muted ms-2">sent: </span>{formatDate(doc?.timesent)}
            </span>
          </Stack>
          <Button variant="dark" className="orange" onClick={() => download(new Uint8Array(doc.data.data), doc.name, "application/pdf")}>
            <FontAwesomeIcon icon={faDownload} /> {isMobile ? '' : 'Download'}
          </Button>
          {currentSigner?.state == 2
            ? <Button className="ml-1" variant="light" onClick={() => moveDoc("archive")}>
                <FontAwesomeIcon icon={faArchive} /> {isMobile ? '' : 'Archive'}
              </Button>
            : <Button variant="dark" className="orange ml-1" onClick={() => moveDoc("unarchive")}>
                <FontAwesomeIcon icon={faInbox} /> {isMobile ? '' : 'Unarchive'}
              </Button>}
        </>
      default: return null;
    }
  }

  if (err?.message) return <p>{err?.message}</p>;
  return <>
  <Col className={!isMobile ? "overflow-x" : "d-none"} style={{ maxHeight: "inherit" }} ref={docRef}>
    <Container fluid className={isMobile ? "bg-white p-2 border-bottom shadow-sm m-0" : "bg-white p-2 border-bottom shadow-sm"}
      style={{ position: "sticky", top: "0px", left: "0px", zIndex: 100, width: isMobile ? `${width - navbarWidth}px` : ''  }}>
      <Stack className={!isMobile ? "" : "d-none"} direction="horizontal" gap={2}>
        {renderButtons()}
        <InputGroup style={{ width: "90px", minWidth: "90px", marginRight: "30px"}}>
          <Form.Control size="sm" type="number" min={80} max={220} step={10} value={setScaleValue()}
            onChange={event => scaling(event)} />
          <InputGroup.Text className="bw-0 br-0 tb-persentage">%</InputGroup.Text>
        </InputGroup>
     </Stack>
      <Stack className={isMobile ? "" : "d-none"} direction="horizontal" gap={2}>
        <div className="d-block">
          {renderButtons()}
        </div>
        <InputGroup style={{ width: "90px", minWidth: "90px" , marginRight: "30px"}}>
          <Form.Control size="sm" type="number" min={80} max={220} step={10} value={setScaleValue()}
            onChange={event => scaling(event)} />
          <InputGroup.Text className="bw-0 br-0 tb-persentage">%</InputGroup.Text>
        </InputGroup>
     </Stack>
    </Container>
    <div className="m-4" ref={boxRef} id="doc">
      {(doc && pdf)
        ? [...Array(pdf.numPages).keys()].map(index => <Page key={index} pdf={pdf} index={index} scale={scale}>
          {inputs.filter(input => input.page === index).map(input => <FillInput key={input.inputid}
            doc={doc} setDoc={setDoc} docRef={docRef} currentSigner={currentSigner} input={input}
            scale={scale} editable={doc.state > 1} />)}
        </Page>)
        : <Container className="text-center">
          <Spinner animation="border" role="status" className="m-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>}
    </div>
    <div className="py-5" />
  </Col>
  <Row className={isMobile ? "overflow" : "d-none"} style={{ maxHeight: "inherit" }} ref={docRef}>
    <Container fluid className={isMobile ? "bg-white p-2 border-bottom shadow-sm m-0" : "bg-white p-2 border-bottom shadow-sm"}
      style={{ position: "sticky", top: "0px", left: "0px", zIndex: 100, width: `${width - navbarWidth}px` }}>
      <Stack style={{ width: isMobile ? `${width - navbarWidth}px` : '' }} direction="horizontal" gap={2}>
        <Row className="ml-1">
          <div className="d-flex flex-wrap">
            {renderButtons()}
            <div className="d-flex ml-auto" style={{marginRight: "20px"}}>
              <InputGroup style={{ width: "90px", minWidth: "90px", marginLeft: "5px"}}>
                <Form.Control size="sm" type="number" min={80} max={220} step={10} value={setScaleValue()}
                  onChange={event => scaling(event)} />
                <InputGroup.Text className="bw-0 br-0 tb-persentage">%</InputGroup.Text>
              </InputGroup>
              <Button variant="light" className="ml-1" onClick={() => mobileScale(true)}>
                +
              </Button>
              <Button variant="light" className="ml-1" onClick={() => mobileScale(false)}>
                -
              </Button>
            </div>
          </div>
        </Row>
      </Stack>
    </Container>
    <div className="m-4" ref={boxRef} id="doc">
      {(doc && pdf)
        ? [...Array(pdf.numPages).keys()].map(index => <Page key={index} pdf={pdf} index={index} scale={scale}>
          {inputs.filter(input => input.page === index).map(input => <FillInput key={input.inputid}
            doc={doc} setDoc={setDoc} docRef={docRef} currentSigner={currentSigner} input={input}
            scale={scale} editable={doc.state > 1} />)}
        </Page>)
        : <Container className="text-center">
          <Spinner animation="border" role="status" className="m-auto">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>}
    </div>
    <div className="py-5" />
  </Row>
    <Outlet context={{ doc, setDoc, setDocs }} />
  </>;
}

function CertificatePopup({ cert }) {
  return (
    <OverlayTrigger trigger={["trigger", "hover"]} placement="bottom" overlay={
      <Popover>
        <Popover.Header>Certificate details</Popover.Header>
        <Popover.Body>
          Issuer: {cert?.issuer?.commonName}, {cert?.issuer?.countryName}<br />
          Validity: {new Date(cert?.validity?.start).toDateString()} to {new Date(cert?.validity?.end).toDateString()}<br />
          Serial: {cert?.serial}
        </Popover.Body>
      </Popover>}>
      <span variant="light">
        <FontAwesomeIcon icon={faCheckCircle} /> Signed
      </span>
    </OverlayTrigger>
  );
}
